<template>
    <v-container fluid>
        <page-banner links="Ma formation/Synoptique"></page-banner>

        <v-row>
            <v-card width="100%" class="my-8 px-5 rounded-0 elevation-1">
                <v-card-text class="black--text body-1">
                    <v-row>
                        <v-col md="8">
                           <p>Cette page vous offre une vision synoptique, une image instantanée de votre formation.
                               Elle a vocation à favoriser la mise en avant de l'alignement pédagogique que vous proposez.</p>
                            <p class="my-0">Celle-ci s'alimente dès que vous générez des activités pour les apprenants dans l'espace "Script" de votre formation.</p>
                        </v-col>
                        <v-col md="2">
                            <v-row class="align-center pt-5 pl-10">
                                <v-avatar
                                    color="green"
                                    size="32"
                                    class="mr-4"
                                ></v-avatar>
                                Présence
                            </v-row>
                        </v-col>
                        <v-col md="2">
                            <v-row class="align-center pt-5">
                                <v-avatar
                                    color="purple lighten-1"
                                    size="32"
                                    class="mr-4"
                                ></v-avatar>
                                Asynchrone en ligne
                            </v-row>
                            <v-row class="align-center mt-6">
                                <v-avatar
                                    color="orange"
                                    size="32"
                                    class="mr-4"
                                ></v-avatar>
                                Synchrone en ligne
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>

        <formation-header></formation-header>

        <v-row>
            <v-timeline align-top class="mx-auto">
                <template v-for="(item, i) in timelineElements" >
                    <v-timeline-item
                        fill-dot
                        :left="item.left"
                        :right="!item.left"
                        :color="item.color"
                        :key="i"
                        v-if="!item.center"
                    >
                        <template>
                            <v-card width="1024px" :class="item.color">
                                <v-card-title class="text-h6 py-2" >
                                    <v-row>
                                        <v-col md="10">{{ item.name }}</v-col>
                                        <v-col md="2">{{ item.learnerDuration | durationToHuman }}</v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-text class="white text-left body-1 pt-4" >
                                    <p>{{ item.learnerActivities || "Activité apprenant (à completer dans le script)" }}</p>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-timeline-item>
                    <v-card v-else :key="i" class="text-center mb-8 background" elevation="0">
                        <span :class="item.type === 'educational' ? 'text-h3' : 'text-h4'">{{ item.name }}</span>
                    </v-card>
                </template>
            </v-timeline>
        </v-row>
    </v-container>
</template>

<script>
import FormationHeader from "../../../components/designer/FormationHeader";
import PageBanner from "../../../components/PageBanner";

import { mapGetters, mapState } from "vuex";

export default {
    name: "Instant",

    components: {
        FormationHeader,
        PageBanner,
    },

    computed: {

        ...mapState("formations", ["currentFormation", "educationalObjectives"]),
        ...mapGetters("formations", ["getOperationalObjectivesByParentId", "getSpecificObjectivesByParentId"]),

        timelineElements() {
            const elements = [];

            this.educationalObjectives.forEach(eduObj => {
                elements.push({ name: eduObj.name, center: true, type: "educational" });
                this.getOperationalObjectivesByParentId(eduObj.id).forEach(opeObj => {
                    elements.push({ name: opeObj.name, center: true, type: "operational" });
                    this.getSpecificObjectivesByParentId(opeObj.id).forEach(speObj => {
                        const obj = { ...speObj };
                        obj.left = false;

                        if(obj.learnerApproach === "En présence") {
                            obj.color = "green";
                            obj.left = true;
                        } else if(obj.method === "Classe virtuelle") {
                            obj.color = "orange"
                        } else {
                            obj.color = "purple lighten-1"
                        }

                        elements.push(obj)
                    });
                });
            });


            return elements;
        }
    }
}
</script>

<style scoped>

</style>